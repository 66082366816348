
import * as $ from 'jquery'
import { Loader } from '@googlemaps/js-api-loader'
import { GridAlgorithm, MarkerClusterer } from '@googlemaps/markerclusterer'
import { isMobile } from '~/helpers/mobile/DeviceType'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'MoleculeGoogleMap',
  props: {
    mapConfig: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    places: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    activeCenterMarkerId: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sdkInitialize: false,
      google: null,
      map: null,
      zoom: 15,
      markers: {
        all: []
      },
      directionsMarkerDefinition: null,
      staticMarkerDefinition: null,
      activeMarker: null,
      directionsService: null,
      directionsDisplay: null,
      geocoder: null,
      bounds: null,
      autocompleteService: null,
      canvasWidth: '100%',
      animationTimeout: null
    }
  },
  computed: {
    isDrawerOpened() {
      return this.$store.state?.base?.filterOpen || false
    },
    isUniversePage() {
      return this.$nuxt.$route.path === '/' && !this.portfolioPageDisabled
    },
    portfolioPageDisabled() {
      return this.$store.state.base?.meta?.generalConfig?.portfolioPageDisabled || false
    }
  },
  watch: {
    isDrawerOpened(newVal, oldVal) {
      if (newVal) {
        // store the timeout value inside an empty variable to prevent the timeout to trigger on fast double closing click event
        this.animationTimeout = setTimeout(() => {
          this.canvasWidth = 'calc(100% - 22.75rem)'
        }, 500)
      } else {
        if (this.animationTimeout) {
          clearTimeout(this.animationTimeout)
        }
        this.canvasWidth = '100%'
      }
    }
  },
  mounted() {
    const self = this
    new Loader({
      apiKey: this.$config.GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places']
    })
      .load()
      .then((google) => {
        self.google = google
        self.initMap()
      })
      .catch((e) => {
        console.log(e)
      })
  },
  updated() {
    if (this.isUniversePage) return
    // TODO: investigate why is this updated called (used)
    this.destroyMap()
    this.markers.all = []
    this.initMap()
  },
  methods: {
    destroyMap() {
      if (!this?.google?.maps) return
      this.google.maps.event.clearInstanceListeners(window)
      this.google.maps.event.clearInstanceListeners(document)
      this.google.maps.event.clearInstanceListeners(this.$refs.map)
    },
    initMap() {
      if (!this?.google?.maps) return
      const self = this
      let lat = this.mapConfig.centerPoint.lat
      let lng = this.mapConfig.centerPoint.lng
      if (this.activeCenterMarkerId) {
        const center = this.mapConfig.staticMarkers.find(
          (item) => item.id === this.activeCenterMarkerId
        )
        lat = center.lat
        lng = center.lng
      }
      const style = this.mapConfig.styleConfig || []
      const controlsStyle = this.moveControls()
      const mapOptions = {
        ...controlsStyle,
        center: {
          lat,
          lng
        },
        styles: style,
        zoom: this.zoom,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: window.innerWidth > 1000
      }
      this.map = new this.google.maps.Map(this.$refs.map, mapOptions)
      this.google.maps.event.addListener(this.map, 'zoom_changed', function () {
        const zoomChangeBoundsListener = self.google.maps.event.addListener(
          self.map,
          'bounds_changed',
          function (event) {
            const maxZoom = 16
            if (self.map.getZoom() > maxZoom && self.map.initialZoom === true) {
              // Change max/min zoom here
              self.map.setZoom(maxZoom)
              self.map.initialZoom = false
            }
            self.google.maps.event.removeListener(zoomChangeBoundsListener)
          }
        )
      })
      this.map.initialZoom = true
      this.autocompleteService = new this.google.maps.places.AutocompleteService()
      this.directionsService = new this.google.maps.DirectionsService()
      this.directionsDisplay = new this.google.maps.DirectionsRenderer({
        polylineOptions: {
          strokeColor: 'black',
          strokeWeight: 6
        },
        // preserveViewport: true,
        suppressMarkers: true
      })
      this.directionsDisplay.setMap(this.map)
      this.geocoder = new this.google.maps.Geocoder()
      this.bounds = new this.google.maps.LatLngBounds()

      this.setupMarkerDefinitions()
      this.generateMarkers()

      if (this.isUniversePage) {
        // group markers only on landing universe page
        this.groupMarkersIntoClusters()
      }
    },
    fitMarkersToMap(specificMarkers = null) {
      try {
        this.bounds = new this.google.maps.LatLngBounds()
        const markers = specificMarkers || [...this.markers.all]
        if (markers?.length === 0) return
        for (const marker of markers) {
          this.bounds.extend(marker.latlng)
        }

        if (!isMobile()) {
          this.extendZoom()
        }
        this.map.fitBounds(this.bounds)
      } catch (e) {
        console.log(e)
      }
    },
    extendZoom() {
      const _this = this
      this.google.maps.event.addListenerOnce(_this.map, 'bounds_changed', function (event) {
        _this.map.setZoom(_this.map.getZoom() - 1)
        if (_this.map.getZoom() > _this.zoom) {
          _this.map.setZoom(_this.zoom)
        }
      })
    },
    addStaticMarker(
      lat,
      lng,
      id,
      title,
      subtitle,
      icon,
      iconSymbol,
      selectable = true,
      visible = true,
      type = 'default',
      tooltipPosition = 'top'
    ) {
      // eslint-disable-next-line new-cap
      return new this.staticMarkerDefinition(new this.google.maps.LatLng(lat, lng), this.map, {
        id,
        title,
        subtitle,
        icon,
        iconSymbol,
        selectable,
        category: 'static-marker',
        visible,
        type,
        tooltipPosition
      })
    },
    addDirectionMarker(
      lat,
      lng,
      id,
      nr,
      icon,
      targetId,
      category,
      transportationType,
      selectable = true,
      visible = true
    ) {
      // eslint-disable-next-line new-cap
      return new this.directionsMarkerDefinition(new this.google.maps.LatLng(lat, lng), this.map, {
        id,
        label: nr,
        icon,
        targetId,
        selectable,
        category,
        transportationType
      })
    },
    generateMarkers() {
      if (this.activeCenterMarkerId) {
        const marker = this.mapConfig.staticMarkers.find(
          (item) => item.id === this.activeCenterMarkerId
        )
        const newMk = this.addStaticMarker(
          marker.lat,
          marker.lng,
          marker.id,
          marker.title,
          marker.subtitle,
          marker.icon,
          marker.iconSymbol,
          true,
          true,
          'default',
          marker.tooltipPosition
        )
        newMk.activate()
        this.activeMarker = newMk
        this.markers.all.push(newMk)
      } else {
        this.mapConfig.staticMarkers.forEach((mk) => {
          const newMk = this.addStaticMarker(
            mk.lat,
            mk.lng,
            mk.id,
            mk.title,
            mk.subtitle,
            mk.icon,
            mk.iconSymbol,
            true,
            true,
            'default',
            mk.tooltipPosition
          )

          if (this.$parent.$props.portfolioData) {
            // TODO: refactoring
            const projects = this.$parent.$props.portfolioData.projects
            const firstProject = projects[0]
            setTimeout(() => {
              if (firstProject.id === mk.id) {
                newMk.activate()
                this.activeMarker = newMk
              }
            }, 1000)
          }

          this.markers.all.push(newMk)
        })
      }

      if (this.activeCenterMarkerId && this.places) {
        this.places.forEach((categoryData) => {
          categoryData.routes.forEach((mk) => {
            const newMk = this.addDirectionMarker(
              mk.lat,
              mk.lng,
              `${categoryData.category}_${mk.id}`,
              mk.order,
              mk.icon,
              this.activeCenterMarkerId,
              categoryData.category,
              mk.mode,
              true,
              true
            )
            this.markers.all.push(newMk)
          })
        })
        return
      } else {
        this.mapConfig.directionalMarkers.forEach((mk) => {
          const newMk = this.addDirectionMarker(
            mk.lat,
            mk.lng,
            mk.id,
            mk.nr,
            mk.icon,
            mk.fromId,
            'unknown',
            'walking',
            true,
            true
          )
          this.markers.all.push(newMk)
        })
      }
      this.fitMarkersToMap()
    },
    setupMarkerDefinitions() {
      const componentScope = this
      const DirectionsMarker = function (latlng, map, args) {
        this.latlng = latlng
        this.args = args
        this.visible = args.visible
        this.setMap(map)
      }

      DirectionsMarker.prototype = new componentScope.google.maps.OverlayView()

      DirectionsMarker.prototype.draw = function () {
        const self = this
        let div = this.div

        if (!div) {
          div = this.div = document.createElement('div')
          div.className = 'custom-marker'
          div.setAttribute('data-marker', self.args.id)
          div.setAttribute('data-category', self.args.category)
          div.setAttribute('data-transportation', self.args.transportationType)

          if (typeof self.args.selectable !== 'undefined' && self.args.selectable === true) {
            const selectElement = document.createElement('span')
            selectElement.className = 'select'
            div.appendChild(selectElement)
          }

          if (typeof self.args.label !== 'undefined') {
            div.append(self.args.label)
          }

          if (typeof self.args.icon !== 'undefined') {
            const oImg = document.createElement('img')
            oImg.setAttribute('src', self.args.icon)
            div.append(oImg)
          }

          if (typeof self.args.marker_id !== 'undefined') {
            div.dataset.marker_id = self.args.marker_id
          }

          div.style.display = 'none'

          div.addEventListener('click', function (event) {
            componentScope.google.maps.event.trigger(self, 'click')
            const element = $(
              '.routeTo[data-marker=' + self.args.category + '-' + self.args.id + ']'
            )
            $('.routeTo').removeClass('active')
            componentScope.resetOldHtmlMarker()
            element.addClass('active')
            componentScope.calcRoute(self, element.data('mode'))

            componentScope.markers.all.forEach((mk) => {
              mk.deactivate()
              if (mk.args.id === self.args.id) {
                mk.activate()
                this.activeMarker = mk
              }
            })
            const list = [...document.getElementsByClassName('atom-location-type')]
            list.forEach((el) => {
              if (el.classList.contains('active')) {
                const getSiblings = function (elem) {
                  return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
                    return sibling !== elem
                  })
                }
                const target = el.querySelector(`[data-marker=${self.args.id}]`)
                target.classList.toggle('atom-route-active')
                getSiblings(target).forEach((sibling) => {
                  sibling.classList.remove('atom-route-active')
                })
              }
            })

            if (!element.parents('.parent').hasClass('active')) {
              element.parents('.parent').addClass('active')
              element.parents('.parent').find('.changeInfoBoxes .circle').html('-')
              element.parents('.parent').find('.streetShow').slideDown(300)
            }
          })

          const panes = this.getPanes()
          panes.overlayImage.appendChild(div)
        }

        const point = this.getProjection().fromLatLngToDivPixel(this.latlng)

        if (point) {
          div.style.left = point.x + 'px'
          div.style.top = point.y + 'px'
        }
      }

      DirectionsMarker.prototype.remove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div)
          this.div = null
        }
      }

      DirectionsMarker.prototype.getPosition = function () {
        return this.latlng
      }

      DirectionsMarker.prototype.hide = function () {
        if (this.div) {
          this.div.style.display = 'none'
        }
      }

      DirectionsMarker.prototype.show = function () {
        if (this.div) {
          this.div.style.display = 'block'
        }
      }

      DirectionsMarker.prototype.getVisible = function () {
        return this.visible
      }

      DirectionsMarker.prototype.activate = function () {
        if (this.div) {
          this.div.classList.add('active')
        }
      }

      DirectionsMarker.prototype.deactivate = function () {
        if (this.div) {
          this.div.classList.remove('active')
        }
      }

      DirectionsMarker.prototype.resetHtml = function () {
        if (this.div) {
          $(this.div).find('.addTime').empty()
        }
      }

      this.directionsMarkerDefinition = DirectionsMarker

      const StaticMarker = function (latlng, map, args) {
        this.latlng = latlng
        this.args = args
        this.visible = args.visible
        this.setMap(map)
      }

      StaticMarker.prototype = new componentScope.google.maps.OverlayView()

      StaticMarker.prototype.draw = function () {
        const self = this
        let div = this.div

        if (!div) {
          div = this.div = document.createElement('div')
          div.classList.add('static-marker')
          div.dataset.project = self.args.id
          if (typeof self.args.marker_id !== 'undefined') {
            div.dataset.marker_id = self.args.marker_id
          }

          if (typeof self.args.title !== 'undefined' || typeof self.args.subtitle !== 'undefined') {
            const title = document.createElement('span')
            title.classList.add('title-marker')
            title.innerHTML += '<b>' + self.args.title + '</b>' + self.args.subtitle
            div.append(title)
          }

          const foundProject =
            componentScope?.$store?.state?.project?.projects?.find(
              (p) => p.slug === self.args.id
            ) || null
          // if (!foundProject) {
          //   div.classList.add('disabled')
          // }

          if (self.args.type === 'cluster') {
            div.classList.add('cluster-marker')
          }

          if (self?.args?.icon) {
            const container = document.createElement('div')
            container.classList.add('icon-container')

            const iconImage = document.createElement('img')
            iconImage.classList.add(`marker-img`)

            if (self.args.type === 'default') {
              iconImage.setAttribute(
                'src',
                `${componentScope.$store.getters['base/cdnBase']}/${self.args.icon}`
              )
            }

            if (self.args.type === 'cluster') {
              container.classList.add('cluster-icon')
              iconImage.setAttribute('src', `${self.args.icon.url}`)
              iconImage.width = 40
              iconImage.height = 40

              const iconLabel = document.createElement('span')
              iconLabel.classList.add('cluster-label')
              iconLabel.innerHTML += '<b>' + self.args.icon.stats + '</b>'
              container.append(iconLabel)
            }
            container.append(iconImage)
            div.append(container)
          }

          if (self?.args?.iconSymbol) {
            const icon = document.createElement('i')
            icon.classList.add(`icon`)
            icon.classList.add(`icon-${self.args.iconSymbol}`)
            icon.classList.add(`marker-icon`)
            div.append(icon)
          }

          if (self?.args?.tooltipPosition === 'bottom') {
            div.classList.add('bottom-tooltip')
          }

          if (componentScope.isUniversePage) {
            div.classList.add('universe-marker')
          }

          div.addEventListener('click', function (event) {
            componentScope.google.maps.event.trigger(self, 'click')

            // if (self.args?.type === 'cluster') {
            //   componentScope.closeFilterDrawer()
            // }

            // make list item active accordingly
            componentScope.markers.all.forEach((mk) => {
              mk.deactivate()
              if (mk.args.id === self.args.id) {
                mk.activate()
                this.activeMarker = mk
              }
            })
            const list = [...document.getElementsByClassName('atom-project-portfolio')]
            list.forEach((el) => {
              el.classList.remove('active')
              if (el.id === self.args.id) {
                el.classList.add('active')
                this.activeMarker = el
              }
            })

            if (!foundProject) return
            componentScope.$router.push({
              path: `/project/${self.args.id}`
            })
          })

          const panes = this.getPanes()
          panes.overlayImage.appendChild(div)
        }

        const point = this.getProjection().fromLatLngToDivPixel(this.latlng)

        if (point) {
          div.style.left = point.x + 'px'
          div.style.top = point.y + 'px'
        }
      }

      StaticMarker.prototype.remove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div)
          this.div = null
        }
      }

      StaticMarker.prototype.getPosition = function () {
        return this.latlng
      }

      StaticMarker.prototype.getVisible = function () {
        return this.visible
      }

      StaticMarker.prototype.activate = function () {
        if (this.div) {
          this.div.classList.add('active')
        }
      }

      StaticMarker.prototype.deactivate = function () {
        if (this.div) {
          this.div.classList.remove('active')
        }
      }

      this.staticMarkerDefinition = StaticMarker
    },
    resetOldHtmlMarker() {
      if (!this.activeCenterMarkerId && this.activeMarker) {
        this.activeMarker.resetHtml()
      }
    },
    calcRoute(marker, travelType = 'WALKING') {
      this.removeDirections()
      $('.distanceFrom .resultText').hide()
      $('.distanceFrom .resultText span').html('')
      let travelMode = this.google.maps.TravelMode.DRIVING

      switch (travelType.toLowerCase()) {
        case 'bicycling':
          travelMode = this.google.maps.TravelMode.BICYCLING
          break
        case 'walking':
          travelMode = this.google.maps.TravelMode.WALKING
          break
        case 'transit':
          travelMode = this.google.maps.TravelMode.TRANSIT
          break
      }

      let destination
      if (typeof marker === 'object') {
        destination = marker
      } else {
        destination = this.markers.all.find((item) => item.args.id === marker)
      }

      const origin = this.activeCenterMarkerId
        ? this.markers.all.find((item) => item.args.id === this.activeCenterMarkerId)
        : this.markers.all.find((item) => item.args.id === destination.args.targetId)
      const request = {
        origin: origin.getPosition(),
        destination: destination.getPosition(),
        travelMode
      }
      const componentScope = this
      let start, end
      this.directionsService.route(request, function (response, status) {
        if (status === componentScope.google.maps.DirectionsStatus.OK) {
          componentScope.directionsDisplay.setDirections(response)
          componentScope.directionsDisplay.setMap(componentScope.map)
          componentScope.activeMarker = destination
          componentScope.activeMarker.activate()
        } else {
          // alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
        }
      })
    },
    removeDirections() {
      this.directionsDisplay.setMap(null)
      if (this.activeMarker) {
        this.activeMarker.deactivate()
      }
    },
    cleanActiveMarker() {
      if (!this.activeMarker) return
      this.activeMarker.div.classList.remove('active')
    },
    cleanActiveListItems() {
      if (!this.activeMarker) return
      const list = [...document.getElementsByClassName('atom-project-portfolio')]
      list.forEach((el) => {
        el.classList.remove('active')
        if (el.id === this.activeMarker.args.id) {
          el.classList.add('active')
        }
      })

      this.markers.all.forEach((mk) => {
        mk.deactivate()
        if (mk.args.id === this.activeMarker.args.id) {
          mk.activate()
          this.activeMarker = mk
        }
      })
      this.fitMarkersToMap(this.markers.all)
    },
    triggerMarkerById(markerId) {
      this.cleanActiveListItems()
      this.cleanActiveMarker()
      this.activeMarker = this.markers.all.find((item) => item.args.id === markerId)
      if (!this.activeMarker) return
      this.activeMarker.div.classList.add('active')
    },
    displayMarkersByCategory(filters) {
      const markersToHide =
        this.markers.all.filter(
          (mk) =>
            mk.args.category !== filters.selectedCategory &&
            mk.args.id !== this.activeCenterMarkerId
        ) ?? []

      markersToHide.forEach((mk) => {
        mk.div.style.display = 'none'
      })
      const markersToHide2 =
        this.markers.all.filter(
          (mk) =>
            mk.args.category === filters.selectedCategory &&
            mk.args.transportationType !== filters.transportationType &&
            mk.args.id !== this.activeCenterMarkerId
        ) ?? []
      markersToHide2.forEach((mk) => {
        mk.div.style.display = 'none'
      })
      const markersToShow =
        this.markers.all.filter(
          (mk) =>
            (mk.args.category === filters.selectedCategory &&
              mk.args.transportationType === filters.transportationType) ||
            mk.args.category === 'static-marker'
        ) ?? []
      markersToShow.forEach((mk) => {
        mk.div.style.display = 'block'
      })
      this.removeDirections()
      this.fitMarkersToMap(markersToShow)
    },
    distanceFrom(address) {
      const self = this
      this.removeDirections()
      this.resetOldHtmlMarker()
      const searchResult = {
        success: true,
        text: '',
        addressText: '',
        directionsResponse: {},
        queryResults: null
      }
      this.geocoder.geocode({ address }, function (results, status) {
        if (status === 'OK') {
          searchResult.queryResults = results
          const origin = self.markers.all.find((e) => e.args.category === 'static-marker')
          const request = {
            origin: origin.getPosition(),
            destination: results[0].geometry.location,
            travelMode: self.google.maps.TravelMode.DRIVING
          }
          self.directionsService.route(request, function (response, status) {
            if (status === self.google.maps.DirectionsStatus.OK) {
              self.directionsDisplay.setDirections(response)
              self.directionsDisplay.setMap(self.map)
              searchResult.success = true
              searchResult.text = `${response.routes[0].legs[0].duration.text} - ${response.routes[0].legs[0].distance.text}`
              searchResult.addressText = `${results[0].formatted_address}`
              searchResult.directionsResponse = response
            } else {
              searchResult.success = false
              searchResult.text = 'The address was not found please enter the zip code'
              searchResult.directionsResponse = response
            }
          })
        } else {
          searchResult.success = false
          searchResult.text = 'This address entered is invalid'
          searchResult.directionsResponse = {}
        }
      })
      return searchResult
    },
    groupMarkersIntoClusters() {
      if (this.markers.all.length === 0) return
      const renderer = {
        render: ({ count, position, markers }) => {
          let dynamicTitle = ''
          for (const [idx, mk] of markers.entries()) {
            dynamicTitle += `<b>${mk.args.title} ${idx !== markers.length - 1 ? '&' : ''}</b>`
          }
          if (count > 2) {
            dynamicTitle = ''
            dynamicTitle += '<b> Zoom for more projects </b>'
          }

          // create a black circle with a white stroke
          const svg = window.btoa(
            `<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                    <circle cx='120' cy='120' r='90' stroke='#ffffff' stroke-width='8' fill='#000000' />
                  </svg>`
          )
          // create a new cluster marker using the new svg icon
          return this.addStaticMarker(
            position.lat(),
            position.lng(),
            `cluster-${String(count)}`,
            dynamicTitle,
            '',
            {
              url: `data:image/svg+xml;base64,${svg}`,
              stats: `${String(count)}`
            },
            null,
            true,
            true,
            'cluster'
          )
        }
      }

      const algo = new GridAlgorithm({
        gridSize: 40
      })

      const cluster = new MarkerClusterer({
        markers: this.markers.all,
        map: this.map,
        algorithm: algo,
        renderer
      })
    },
    moveControls() {
      return {
        zoomControlOptions: {
          position: this.google.maps.ControlPosition.LEFT_CENTER
        }
      }
    },
    closeFilterDrawer() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_FILTER))
    },
    centerMapByMarkerPosition(markerId, zoomToBuildingCenter) {
      const marker = this.markers?.all?.find((mk) => mk.args.id === markerId) || null
      if (!marker) return
      if (zoomToBuildingCenter) {
        this.map.setCenter(marker.getPosition())
        this.map.setZoom(15)
      } else {
        this.fitMarkersToMap()
      }
    }
  }
}
